module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Why Partners","short_name":"Why","start_url":"/","background_color":"#fff","theme_color":"#17161D","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ada47fe49a9b115f0f21cb79aa696a74"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"whypartnerscom","accessToken":"MC5YOUhaUGhJQUFDWUF4MFd3.77-977-977-9MAgGAy8IcD3vv70F77-977-9ce-_vWHvv71WLRHvv714X--_vXQ8Tu-_vXDvv70","schemas":{"frame":{"Main":{},"Footer":{"copyright":{"type":"Text","config":{"label":"Copyright"}},"linkedin_url":{"type":"Link","config":{"label":"Linkedin URL"}},"instagram_url":{"type":"Link","config":{"label":"Instagram URL"}}}},"home":{"Main":{"meta_title":{"type":"Text","config":{"label":"Meta Title"}},"meta_description":{"type":"Text","config":{"label":"Meta Description"}}},"Hero":{"title_pre":{"type":"Text","config":{"label":"Title Pre"}},"title_tag":{"type":"Group","config":{"fields":{"tag":{"type":"Text","config":{"label":"tag"}},"color":{"type":"Color","config":{"label":"color"}}},"label":"Title Tag"}},"title_post":{"type":"Text","config":{"label":"Title Post"}},"lead":{"type":"Text","config":{"label":"Lead"}}},"Partners":{"partner":{"type":"Group","config":{"fields":{"svg_logo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"SVG Logo"}},"name":{"type":"Text","config":{"label":"Name"}}},"label":"Partner"}}},"Team":{"section_title":{"type":"StructuredText","config":{"single":"heading3","label":"Section title"}},"members":{"type":"Group","config":{"fields":{"photo":{"type":"Image","config":{"constraint":{"width":225,"height":225},"thumbnails":[],"label":"Photo"}},"name":{"type":"Text","config":{"label":"Name"}},"role":{"type":"Text","config":{"label":"Role"}},"linkedin_url":{"type":"Link","config":{"label":"Linkedin URL"}},"color":{"type":"Color","config":{"label":"Color"}}},"label":"Members"}}},"Contact":{"contact_cta":{"type":"Text","config":{"label":"Contact CTA"}},"contact_email":{"type":"Text","config":{"label":"Contact Email"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-101056148-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    }]
